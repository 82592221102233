import { render, staticRenderFns } from "./supperlier-testing.vue?vue&type=template&id=bd67ec42&scoped=true&"
import script from "./supperlier-testing.vue?vue&type=script&lang=js&"
export * from "./supperlier-testing.vue?vue&type=script&lang=js&"
import style0 from "./supperlier-testing.vue?vue&type=style&index=0&id=bd67ec42&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd67ec42",
  null
  
)

export default component.exports