<template>
  <div class="content">
    <backTitle lable="供应商管理"></backTitle>
    <div class="whitebox" id="content" @scroll="setScroll">
      <!-- 右侧楼层 -->
      <div class="point">
        <div class="point-top"><i class="iconfont icondaohang"></i></div>
        <div
          v-for="(item, index) of sideMenuList"
          :key="index"
          class="item"
          :class="{ itemactive: active === item.id }"
          @click="touchActive(item.id)"
        >
          <span>{{ item.label }}</span>
        </div>
        <div class="scrollTop" @click="touchActive(0)">
          <i class="iconfont icontop"></i>
        </div>
      </div>

      <div class="left">
        <el-collapse v-model="activeNames">
          <!-- 基本信息 -->
          <el-collapse-item name="1">
            <template slot="title">
              <new-text-title index="01" label="基本信息"></new-text-title>
            </template>
            <div class="leftitem">
              <basic-info
                v-if="$route.query.type === 'details'"
                :type="'bsc'"
                :basic-data="baseQueryParas"
                :authCapitalData="authCapitalData"
              ></basic-info>
              <BaseForm
                v-else
                :componentList="basicFromConfig"
                :showBtns="false"
                :formAttrs="{
                  model: baseQueryParas,
                  labelWidth: '180px'
                }"
                :type="type"
                :baseQueryParas.sync="baseQueryParas"
                class="formStyle"
                ref="fromBasicsInfo"
              >
              </BaseForm>
            </div>
          </el-collapse-item>
          <!-- EAS信息 | 法务平台信息 -->
          <el-collapse-item name="2">
            <template slot="title">
              <new-text-title index='02' label="法务平台信息"></new-text-title>
            </template>
            <div class="leftitem">
              <Basetable
                :columns="esacFromConfig"
                :showPage="false"
                :tableAttrs="{
                  data: easQueryParas,
                  stripe: true,
                  'max-height': '500'
                }"
                ref="accounttableData"
                :is-caculate-height="false"
                :webPage="false"
                class="table"
              >
                <template slot="index" slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </Basetable>
            </div>
          </el-collapse-item>
          <!-- 账户信息 -->
          <el-collapse-item name="3">
            <template slot="title">
              <new-text-title index="03" label="账户信息"></new-text-title>
            </template>
            <div class="leftitem">
              <div class="addbtn" v-if="$route.query.type !== 'details'">
                <div class="addbutton" @click="addTableItem('accoun')">
                  <i class="el-icon-plus"></i>
                </div>
              </div>
              <Basetable
                :columns="accountcolumns"
                :showPage="false"
                :tableAttrs="{
                  data: accountData.records,
                  stripe: true,
                  'max-height': '500'
                }"
                ref="accounttableData"
                @pageSizeChange="accountpageSizeChange"
                @pageIndexChange="accountPageIndexChange"
                :webPage="true"
                :is-caculate-height="false"
                class="table"
              >
                <template slot="action" slot-scope="scope">
                  <IconButton
                    :disabled="$route.query.type === 'details'"
                    v-if="$route.query.type !== 'details'"
                    content="删除"
                    @click.prevent="
                      removeRow('accountData', scope.$index, scope.row)
                    "
                    icon="iconfont iconshanchu1"
                  ></IconButton>
                </template>
                <template slot="account" slot-scope="scope">
                  <div
                    v-if="
                      scope.row.addtype && parseInt(scope.row.accountType) !== 3
                    "
                  >
                    <el-input
                      v-model="scope.row.account"
                      placeholder="请输入"
                    ></el-input>
                  </div>
                  <div v-else>{{ scope.row.account }}</div>
                </template>
                <template slot="bankName" slot-scope="scope">
                  <div
                    v-if="
                      scope.row.addtype && parseInt(scope.row.accountType) !== 3
                    "
                  >
                    <!-- <el-select
                      v-if="scope.row.accountType === '0'"
                      v-model="scope.row.bankName"
                    >
                      <el-option
                        v-for="item in backAccount"
                        :key="item.dictId"
                        :label="item.dictName"
                        :value="item.dictId"
                      >
                      </el-option>
                    </el-select> -->
                    <el-input
                      v-model="scope.row.bankName"
                      placeholder="请输入"
                    ></el-input>
                  </div>
                  <div v-else>{{ scope.row.bankName }}</div>
                </template>
                <template slot="accountNo" slot-scope="scope">
                  <div
                    v-if="
                      scope.row.addtype && parseInt(scope.row.accountType) !== 3
                    "
                  >
                    <el-input
                      v-model="scope.row.accountNo"
                      maxLength="100"
                      placeholder="请输入"
                      @input="
                        scope.row.accountNo = scope.row.accountNo.replace(
                          /[^\d]/g,
                          ''
                        )
                      "
                    ></el-input>
                  </div>
                  <div v-else>{{ scope.row.accountNo }}</div>
                </template>
                <template slot="accountType" slot-scope="scope">
                  <div
                    v-if="
                      scope.row.addtype && parseInt(scope.row.accountType) !== 3
                    "
                  >
                    <el-select
                      v-model="scope.row.accountType"
                      @focus="accountScreen(scope.row)"
                      @change="
                        changeAccountType(scope.row.accountType, scope.$index)
                      "
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in bankType"
                        :key="item.dictId"
                        :label="item.dictName"
                        :value="item.dictId"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div v-else>{{ setAccountType(scope.row.accountType) }}</div>
                </template>
              </Basetable>
            </div>
          </el-collapse-item>

          <!-- 人员信息 -->
          <el-collapse-item name="4">
            <template slot="title">
              <new-text-title index="04" label="人员信息"></new-text-title>
            </template>
            <div class="leftitem">
              <div class="addbtn" v-if="$route.query.type !== 'details'">
                <div class="addbutton" @click="addTableItem('personnel')">
                  <i class="el-icon-plus"></i>
                </div>
              </div>
              <Basetable
                :columns="personnelcolumns"
                :showPage="false"
                :tableAttrs="{
                  data: personnelData.records,
                  stripe: true,
                  'max-height': '500'
                }"
                @expand-change="openPersonnel"
                ref="personnetableData"
                :webPage="false"
                :is-caculate-height="false"
                @pageSizeChange="personnelPageSizeChange"
                @pageIndexChange="personnelPageIndexChange"
                class="table"
              >
                <template slot="code" slot-scope="scope">
                  <Basetable
                    :columns="personnelRelationColumns"
                    :showPage="false"
                    :tableAttrs="{
                      data: scope.row.personContactInfos,
                      stripe: true
                    }"
                    ref="personnetableData"
                    :webPage="false"
                    class="table"
                  >
                    <template slot="personnalName" slot-scope="scope">
                      <el-input
                        :disabled="$route.query.type === 'details'"
                        maxlength="20"
                        placeholder="请输入"
                        @input="
                          scope.row.personnalName = scope.row.personnalName.replace(
                            /[^\a-\z\A-\Z\u4E00-\u9FA5\.\,\?\<\>\。\，\-\_\——\=\;\@\！\!\+\$]/g,
                            ''
                          )
                        "
                        v-model="scope.row.personnalName"
                      ></el-input>
                    </template>
                    <template slot="idCard" slot-scope="scope">
                      <el-input
                        :disabled="$route.query.type === 'details'"
                        maxlength="18"
                        placeholder="请输入"
                        @input="
                          scope.row.idCard = scope.row.idCard.replace(
                            /[^a-zA-Z0-9()（）]/g,
                            ''
                          )
                        "
                        v-model="scope.row.idCard"
                      ></el-input>
                    </template>
                    <template slot="mobilePhoneNo" slot-scope="scope">
                      <el-input
                        :disabled="$route.query.type === 'details'"
                        maxlength="11"
                        placeholder="请输入"
                        v-model="scope.row.mobilePhoneNo"
                        @input="
                          scope.row.mobilePhoneNo = scope.row.mobilePhoneNo.replace(
                            /[^\d]/g,
                            ''
                          )
                        "
                        @blur="blurPhone(scope.row.mobilePhoneNo)"
                      ></el-input>
                    </template>
                    <template slot="address" slot-scope="scope">
                      <el-input
                        :disabled="$route.query.type === 'details'"
                        maxlength="50"
                        placeholder="请输入"
                        v-model="scope.row.address"
                      ></el-input>
                    </template>
                    <template slot="dutiesName" slot-scope="scope">
                      <el-input
                        :disabled="$route.query.type === 'details'"
                        maxlength="20"
                        placeholder="请输入"
                        @input="
                          scope.row.dutiesName = scope.row.dutiesName.replace(
                            /[^\u4E00-\u9FA5]/g,
                            ''
                          )
                        "
                        v-model="scope.row.dutiesName"
                      ></el-input>
                    </template>
                    <template slot="contactType" slot-scope="scope">
                      <base-select
                        :disabled="$route.query.type === 'details'"
                        v-model="scope.row.contactType"
                        placeholder="请选择"
                        :options="getDictLists('PERSON_CONTACT_TYPE')"
                        :selectedField="['dictId', 'dictName']"
                      ></base-select>
                    </template>
                    <template slot="actionHeader">
                      <div class="addRelation">
                        <div>操作</div>
                        <div
                          @click="addRelation(scope.row, scope.$index)"
                          v-if="$route.query.type !== 'details'"
                        >
                          <i class="el-icon-plus"></i>
                        </div>
                      </div>
                    </template>
                    <template slot="action" slot-scope="newScope">
                      <IconButton
                        content="删除"
                        v-if="$route.query.type !== 'details'"
                        @click="removeRelation(scope.$index, newScope.$index)"
                        icon="iconfont iconshanchu1"
                      ></IconButton>
                    </template>
                  </Basetable>
                </template>
                <template slot="action" slot-scope="scope">
                  <IconButton
                    content="删除"
                    :disabled="$route.query.type === 'details'"
                    v-if="$route.query.type !== 'details'"
                    @click.prevent="
                      removeRow('personnelData', scope.$index, scope.row)
                    "
                    icon="iconfont iconshanchu1"
                  ></IconButton>
                </template>
                <template slot="personnerName" slot-scope="scope">
                  <div
                    v-if="
                      scope.row.addtype && parseInt(scope.row.easUser) !== 1
                    "
                  >
                    <el-input
                      v-model="scope.row.personnerName"
                      placeholder="请输入"
                      @input="
                        scope.row.personnerName = scope.row.personnerName.replace(
                          /[,]/g,
                          ''
                        )
                      "
                    ></el-input>
                  </div>
                  <div v-else>{{ scope.row.personnerName }}</div>
                </template>
                <template slot="address" slot-scope="scope">
                  <div
                    v-if="
                      scope.row.addtype && parseInt(scope.row.easUser) !== 1
                    "
                  >
                    <el-input
                      maxlength="50"
                      v-model="scope.row.address"
                      placeholder="请输入"
                      @input="
                        scope.row.address = scope.row.address.replace(
                          /[,]/g,
                          ''
                        )
                      "
                    ></el-input>
                  </div>
                  <div v-else>
                    {{ scope.row.address ? scope.row.address : "--" }}
                  </div>
                </template>
                <template slot="idCard" slot-scope="scope">
                  <div
                    v-if="
                      scope.row.addtype && parseInt(scope.row.easUser) !== 1
                    "
                  >
                    <el-input
                      v-model="scope.row.idCard"
                      maxlength="18"
                      placeholder="请输入"
                      @input="
                        scope.row.idCard = scope.row.idCard.replace(
                          /[^a-zA-Z0-9()（）]/g,
                          ''
                        )
                      "
                    ></el-input>
                  </div>
                  <div v-else>{{ scope.row.idCard }}</div>
                </template>
                <template slot="email" slot-scope="scope">
                  <div
                    v-if="
                      scope.row.addtype && parseInt(scope.row.easUser) !== 1
                    "
                  >
                    <el-input
                      v-model="scope.row.email"
                      placeholder="请输入"
                      @blur="blurEmail(scope.row.email)"
                    ></el-input>
                  </div>
                  <div v-else>{{ scope.row.email }}</div>
                </template>
                <template slot="mobilePhoneNo" slot-scope="scope">
                  <div
                    v-if="
                      scope.row.addtype && parseInt(scope.row.easUser) !== 1
                    "
                  >
                    <el-input
                      v-model="scope.row.mobilePhoneNo"
                      maxlength="11"
                      placeholder="请输入"
                      @input="
                        scope.row.mobilePhoneNo = scope.row.mobilePhoneNo.replace(
                          /[^\d]/g,
                          ''
                        )
                      "
                      @blur="blurPhone(scope.row.mobilePhoneNo)"
                    ></el-input>
                  </div>
                  <div v-else>{{ scope.row.mobilePhoneNo }}</div>
                </template>
                <template slot="dutiesName" slot-scope="scope">
                  <div
                    v-if="
                      scope.row.addtype && parseInt(scope.row.easUser) !== 1
                    "
                  >
                    <el-input
                      v-model="scope.row.dutiesName"
                      placeholder="请输入"
                    ></el-input>
                  </div>
                  <div v-else>{{ scope.row.dutiesName }}</div>
                </template>

                <template slot="dictId" slot-scope="scope">
                  <div
                    v-if="
                      scope.row.addtype && parseInt(scope.row.easUser) !== 1
                    "
                  >
                    <el-select
                      multiple
                      v-model="scope.row.dictId"
                      placeholder="请选择"
                      @change="dutiesIdChange(scope.row.dictId, scope.$index)"
                    >
                      <el-option
                        v-for="item in categorYoptions"
                        :key="item.dictId"
                        :label="item.dictName"
                        :value="item.dictId"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div v-else>{{ getPersonnelType(scope.row.dictId) }}</div>
                </template>
                <template slot="createAccountFlag" slot-scope="scope">
                  <base-select
                    :disabled="$route.query.type === 'details'"
                    v-model="scope.row.createAccountFlag"
                    placeholder="请选择"
                    :options="flagList"
                  ></base-select>
                </template>
              </Basetable>
            </div>
          </el-collapse-item>

          <!-- 附件信息 -->
          <el-collapse-item name="5">
            <template slot="title">
              <new-text-title index="05" label="文件信息"></new-text-title>
            </template>
            <Basetable
              :columns="filefromconfigcolumns"
              :showPage="false"
              :tableAttrs="{
                data: fileFromtypeList,
                stripe: true
              }"
              ref="filetableData"
              :data-source.sync="fileFromtypeList"
              :webPage="false"
              @expand-change="openResumeRow"
              :is-caculate-height="false"
              class="tableFile"
            >
              <template slot="code" slot-scope="scope">
                <Basetable
                  :columns="fileList"
                  :showPage="false"
                  :tableAttrs="{
                    data: scope.row.chide,
                    stripe: true
                  }"
                  ref="tableData"
                  :webPage="false"
                  :selfChangeHeight="120"
                  class="childertable"
                >
                  <template slot="action" slot-scope="scope">
                    <IconButton
                      v-if="$route.query.type !== 'details'"
                      content="删除"
                      @click.prevent="removeResume(scope.$index, scope.row)"
                      icon="iconfont iconshanchu1"
                    ></IconButton>
                    <IconButton
                      content="预览"
                      @click.prevent="fileview(scope.$index, scope.row, 'tab1')"
                      icon="iconfont iconyulan"
                    ></IconButton>
                  </template>
                </Basetable>
              </template>
              <template slot="index" slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
              <template slot="action" slot-scope="scope">
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="上传"
                    placement="top"
                  >
                    <div v-if="$route.query.type !== 'details'">
                      <uploadFile
                        ref="upload"
                        @uploadFile="UploadFile"
                        :tabRow="scope"
                      ></uploadFile>
                    </div>
                  </el-tooltip>
                  <div>
                    <IconButton
                      @click.prevent="
                        openFilesResume(scope.$index, scope.row, 'tab2')
                      "
                      content="查看履历"
                      icon="iconfont iconlvli"
                    ></IconButton>
                  </div>
                </div>
              </template>
            </Basetable>
          </el-collapse-item>

          <!-- EAS附件 -->
          <el-collapse-item name="6">
            <template slot="title">
              <new-text-title index='06' label="法务平台附件"></new-text-title>
            </template>
            <div class="box" style="min-height: 400px">
              <div class="dowload">
                <base-button
                  label="下载全部"
                  @click="downAll"
                  icon="iconfont iconxiazai"
                  class="downAll"
                ></base-button>
              </div>
              <div class="filebox">
                <div
                  class="fileitem"
                  v-for="(item, index) of easFileList"
                  :key="index"
                >
                  <i
                    class="iconfont font26"
                    :class="addFontIcon(item.suffix)"
                  ></i>
                  <div class="fileName">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="item.fileName"
                      placement="top-start"
                      :disabled="getwidth(item.fileName, index)"
                    >
                      <div class="fileName">
                        <el-link type="primary" @click="downloadEnclosure(item)"
                          >{{ index + 1 }}.{{ item.fileName }}
                        </el-link>
                      </div>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item name="7">
            <template slot="title">
              <new-text-title index="07" label="财务信息"></new-text-title>
            </template>
            <financial-information
              :financialData="financialData"
              @changeFinancialData="changeFinancialData"
              :type="$route.query.type"
            ></financial-information>
          </el-collapse-item>
          <el-collapse-item name="8">
            <template slot="title">
              <new-text-title index="08" label="诉讼信息"></new-text-title>
            </template>
            <litigation-information
              :litigationListData.sync="litigationListData"
              @changeLitigationListData="changeLitigationListData"
              :enterpriseName="baseQueryParas.enterpriseName"
              :type="$route.query.type"
            />
          </el-collapse-item>
        </el-collapse>
      </div>

      <!-- 文件预览 -->
      <pre-view-new
        :count="Count"
        :fileId="fileId"
        :fileType="fileType"
        :isOpen="true"
        ref="preView"
      />
      <!-- 文件履历弹窗  -->
      <files-resume-dialog
        :visible.sync="visibleFilsResume"
        :filesResumeData="filesResumeData"
        :fileTypeName="fileTypeName"
      ></files-resume-dialog>
    </div>

    <div class="footer">
      <base-button
        label="提 交"
        @click="formValidate"
        v-if="$route.query.type !== 'details'"
      ></base-button>
      <base-button
        label="关 闭"
        type="default"
        @click="$router.back()"
      ></base-button>
    </div>
  </div>
</template>
<script>
import { getDictNameMaps, getDictLists } from '@/filters/fromDict'
import LitigationInformation from './components/litigation-information.vue'
import FinancialInformation from './components/financial-information.vue'
import BaseSelect from '@/components/common/base-select/base-select.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import BackTitle from '@/pages/business/components/backtitle.vue'
import BaseForm from '@/components/common/base-form/base-form.vue'
import Basetable from '@/components/common/table/base-table/base-table'
import NewTextTitle from '@/components/packages/text-title/text-title'
import BaseButton from '@/components/common/button/base-button/base-button'
import UploadFile from '@/pages/basic-data-management/supplier-management/components/supplier-upload'
import BasicInfo from '@/pages/basic-data-management/supplier-management/components/supplier-basicInfo'
import PreViewNew from '@/components/pre-view/pre-view.vue'
import {
  supplierApi,
  supplierApiPage,
  supplierFileApi,
  analysisApi,
  litigationApi
} from '@/api/companyApi'
import { suppliersApi } from '@/api/productApi'
import FilesResumeDialog from './components/files-resume-dialog.vue'
import {
  basicFromConfig,
  esacFromConfig,
  qualificationscolumns,
  accountcolumns,
  personnelcolumns,
  filefromconfig,
  sideMenu,
  fileList,
  personnelRelationColumns
} from './utils/addsupplier.confg.js'
import { downAPi } from '@/api/fileApi'
import { downFile } from '@/utils/devUtils'
import { validPhone } from '@/utils/validate'
import { idCardReg, phoneReg, emailReg } from '@/config/reg-config'
function findChildByClassName (node, name) {
  let containerBody = null

  const findCurrentNode = el => {
    const children = el.children || []
    for (let i = 0; i <= children.length; i++) {
      const child = children[i]
      if (!child) {
        return
      }
      if (child.classList && child.classList.contains(name)) {
        containerBody = child
      } else {
        child.children.length && findCurrentNode(child)
      }
    }
  }
  findCurrentNode(node)
  return containerBody
}

export default {
  components: {
    BaseSelect,
    BaseForm,
    Basetable,
    NewTextTitle,
    BaseButton,
    BackTitle,
    IconButton,
    UploadFile,
    BasicInfo,
    PreViewNew,
    FilesResumeDialog,
    FinancialInformation,
    LitigationInformation
  },
  data () {
    return {
      editorType: '',
      financialData: [], // 财务信息数据
      fileTypeName: '',
      easFileList: [],
      dialogVisible: false,
      Count: 1,
      fileId: '',
      fileType: '',
      visibleView: false,
      tabRow: {},
      childrenFileList: [],
      fileFromtypeList: [], // 附件信息，表格数据
      categorYoptions: [],
      bankType: [],
      delectpersonnelList: [],
      delectbankAccountList: [],
      active: 0,
      enterpriseId: '',
      type: '',
      accotQueryParas: {
        pageIndex: 1,
        pageSize: 999999,
        enterpriseId: this.$route.query.keyId
      },
      peranLqueryParas: {
        pageIndex: 1,
        pageSize: 99999999,
        enterpriseId: this.$route.query.keyId
      },
      baseQueryParas: { capitalSideCodeList: [] },
      easQueryParas: [],
      accountData: {
        records: []
      },
      personnelData: {
        records: []
      },
      fileData: [], // 附件文件列表
      // 折叠面板默认全部激活
      activeNames: ['1', '2', '3', '4', '5', '6', '7', '8'],
      colIndex: 0,
      filesResumeData: [], // 文件履历数据
      visibleFilsResume: false, // 文件履历弹窗
      TemplateData: {
        year: '',
        cashEquivalents: '',
        accountsReceivable: '',
        otherReceivables: '',
        prepayment: '',
        totalAssets: '',
        totalLiability: '',
        primeOperatingRevenue: '',
        netProfit: '',
        netProfitRate: '',
        debtAssetRatio: '',
        receivablesTurnoverRatio: '',
        cashRatio: ''
      }, // 财务信息模板数据
      litigationListData: [], // 诉讼信息数据
      authCapitalData: [], // 适用资方
      flagList: [
        { id: 1, name: '是' },
        { id: 0, name: '否' }
      ]
    }
  },
  computed: {
    litigationApi () {
      return litigationApi
    },
    analysisApi () {
      return analysisApi
    },
    personnelRelationColumns () {
      return personnelRelationColumns(this)
    },
    basicFromConfig () {
      return basicFromConfig(this)
    },
    personnelcolumns () {
      return personnelcolumns(this)
    },
    accountcolumns () {
      return accountcolumns(this)
    },
    esacFromConfig () {
      return esacFromConfig(this)
    },
    qualificationscolumns () {
      return qualificationscolumns(this)
    },
    filefromconfigcolumns () {
      return filefromconfig(this)
    },
    sideMenuList () {
      return sideMenu(this)
    },
    fileList () {
      return fileList(this)
    },
    api () {
      return supplierApiPage
    },
    supplierApi () {
      return supplierApi
    },
    supplierFileApi () {
      return supplierFileApi
    },
    getDictLists () {
      return getDictLists
    },
    // 开户行数据
    backAccount () {
      return getDictLists('DEFAULT_SUPERVISE_BANK_ACCOUNT')
    }
  },
  watch: {
    'baseQueryParas.companyInfo': {
      handler (val) {
        for (var key in val) {
          this.baseQueryParas[key] = val[key]
        }
      }
    }
  },
  created () {
    this.enterpriseId = this.$route.query.keyId
    this.editorType = this.$route.query.type
  },
  mounted () {
    this.initData()
    this.getFileTemplate()
    this.getAccountType()
    this.getPersonnel()
    this.queryAuthCapital()
    if (this.$route.query.type !== 'add') {
      this.getLitigationList()
      this.getAnalysisList()
    }
  },
  methods: {
    // 获取适用资方数据
    queryAuthCapital () {
      suppliersApi.queryAuthCapital().then(res => {
        this.authCapitalData = res.data
        console.log(this.authCapitalData)
      })
    },
    // 改变诉讼信息
    changeLitigationListData (data) {
      this.litigationListData.push.apply(this.litigationListData, data)
      const result = []
      const obj = {}
      for (var i = 0; i < this.litigationListData.length; i++) {
        if (!obj[this.litigationListData[i].caseNumber]) {
          result.push(this.litigationListData[i])
          obj[this.litigationListData[i].caseNumber] = true
        }
      }
      this.litigationListData = result
      console.log(result)
    },
    // 获取诉讼信息
    getLitigationList () {
      if (!this.enterpriseId) {
        return
      }
      const params = {
        companyId: this.enterpriseId
      }
      this.litigationApi.getLitigationList(params).then(res => {
        this.litigationListData = res.data
      })
    },
    // 改变财务信息
    changeFinancialData (data) {
      const oldArr = JSON.parse(JSON.stringify(this.financialData))
      const indexArr = []
      oldArr.forEach((item, index) => {
        data.forEach(elem => {
          if (item.year === elem.year) {
            indexArr.push(index)
          }
        })
      })
      const newArr = []
      oldArr.forEach((item, index) => {
        if (indexArr.indexOf(index) < 0) {
          newArr.push(item)
        }
      })
      newArr.push.apply(newArr, data)
      this.financialData = this.formatAnalysisList(newArr)
    },
    // 财务信息数据格式化
    formatAnalysisList (data) {
      const newArr = []
      data.forEach(item => {
        const obj = {}
        for (const key in this.TemplateData) {
          for (const j in item) {
            if (key === j) {
              obj[key] = JSON.parse(JSON.stringify(item[j]))
            }
          }
        }
        newArr.push(obj)
      })
      return newArr
    },
    // 获取财务信息列表
    getAnalysisList () {
      if (!this.enterpriseId) {
        return
      }
      const params = {
        companyId: this.enterpriseId
      }
      this.analysisApi.getAnalysisList(params).then(res => {
        if (res.data) {
          this.financialData = this.formatAnalysisList(res.data)
          console.log(this.financialData, '222')
        }
      })
    },
    // 删除子表格数据
    removeRelation (index, i) {
      this.personnelData.records[index].personContactInfos.splice(i, 1)
    },
    // 身份证校验
    idCardPhone (data) {
      if (!idCardReg.test(data)) {
        this.state = false
        return this.warning('请输入正确的身份证')
      } else {
        this.state = true
      }
    },
    // 手机号校验
    blurPhone (data) {
      if (!phoneReg.test(data)) {
        this.state = false
        return this.warning('请输入正确的手机号')
      } else {
        this.state = true
      }
    },
    blurEmail (data) {
      if (data && !emailReg.test(data)) {
        this.state = false
        return this.warning('请输入正确的邮箱地址')
      } else {
        this.state = true
      }
    },
    // 新增关系人
    addRelation (row, i) {
      console.log(this.personnelData.records[i], i)
      this.personnelData.records[i].personContactInfos.push({ idCard: '' })
    },
    /**
     * 数据初始化
     */
    initData () {
      const keyId = this.$route.query.keyId
      if (keyId) {
        this.supplierApi.get(keyId).then(res => {
          this.baseQueryParas = res.data.companyInfo
          this.$set(this.baseQueryParas, 'locationCity', [
            this.baseQueryParas.manageProvince,
            this.baseQueryParas.manageCity
          ])
          // 选中适用资金方列表数据
          if (res.data.companyInfo.supplierAuthCapitalList) {
            const supplierAuthCapitalList =
              res.data.companyInfo.supplierAuthCapitalList
            this.$set(this.baseQueryParas, 'capitalSideCodeList', [])
            supplierAuthCapitalList.forEach(ele => {
              this.baseQueryParas.capitalSideCodeList.push(ele.capitalSideCode)
            })
          }
          this.easQueryParas = res.data.easInfoList
          this.accountData.records = res.data.bankAccountList || []
          this.personnelData.records = res.data.personnelList || []
          this.personnelData.records.forEach((item, index) => {
            if (item.dictId) {
              item.dictId = item.dictId.split(',')
            }
            if (!item.personContactInfos) {
              this.$set(
                this.personnelData.records[index],
                'personContactInfos',
                []
              )
            }
          })
          this.easFileList = res.data.easfileList
          this.fileData = res.data.fileList
          // 人员，账户信息总条数
          this.$refs.accounttableData.total = this.accountData.total || 0
          this.$refs.personnetableData.total = this.personnelData.total || 0
          this.editTable()
        })
      }
    },
    /**
     *  城市数据处理
     * @param city
     * @returns {string|*}
     */
    cityFormat (city) {
      if (city) {
        if (typeof city === 'string') {
          return city
        } else {
          const data = JSON.parse(city)
          const [cityd, province] = data
          return province + cityd
        }
      }
    },
    /**
     * 获取账号信息的账号类型
     */
    getAccountType () {
      const data = getDictLists('SUPPLIER_BANK_TYPE')
      if (data) {
        data.forEach(item => {
          this.bankType.push({
            dictName: item.dictName,
            dictId: item.dictId
          })
        })
      }
    },

    /**
     *  账号类型筛选
     * @param row
     */
    accountScreen (row) {
      if (parseInt(row.accountType) !== 3) {
        this.bankType = this.bankType.filter(
          item => parseInt(item.dictId) !== 3
        )
      }
    },
    // 改变账户类型
    changeAccountType (data, index) {
      if (data === '0') {
        // if (this.backAccount.length > 0) {
        //   this.$set(
        //     this.accountData.records[index],
        //     'bankName',
        //     this.backAccount[0].dictId
        //   )
        // }
      }
    },
    /**
     * 返回账户类型
     * @param id
     * @returns {*}
     */
    setAccountType (id) {
      const data = getDictLists('SUPPLIER_BANK_TYPE').find(
        item => parseInt(item.dictId) === parseInt(id)
      )
      if (data) {
        return data.dictName
      }
    },

    /**
     * 法人检验
     * @param dutiesI
     * @param index ：索引
     * @returns {ElMessageComponent}
     */
    dutiesIdChange (dutiesI, index) {
      if (dutiesI) {
        dutiesI.forEach(elem => {
          if (elem === 'LEGAL_PERSON') {
            if (this.personnelData.records) {
              const dutiesIArr = this.personnelData.records.filter(
                item => item.dictId.indexOf(elem) !== -1
              )
              if (dutiesIArr.length > 1) {
                this.personnelData.records[index].dictId = ''
                this.$set(
                  this.personnelData.records,
                  index,
                  this.personnelData.records[index]
                )
                return this.$message.error('法人只能存在一个')
              }
            }
          }
        })
      }
    },

    /***
     * 校验账号信息是否填写完整
     */
    validateAcccount () {
      if (this.accountData && this.accountData.records) {
        const accountDataList = this.setParams(
          this.accountData,
          this.accountData.records
        )
        // const reg = /^[\u0391-\uFFE5A-Za-z]/
        // const accountReg = /^\d+$/
        if (accountDataList && accountDataList.records) {
          for (const item of accountDataList.records) {
            // if (
            //   item.accountType !== 3 &&
            //   item.accountType !== '3' &&
            //   (!item.account || !reg.test(item.account))
            // ) {
            //   return '请输入正确的户名'
            // } else if (
            //   item.accountType !== 3 &&
            //   item.accountType !== '3' &&
            //   (!item.bankName || !reg.test(item.bankName))
            // ) {
            //   return '请输入正确的开户行'
            // } else if (
            //   item.accountType !== 3 &&
            //   item.accountType !== '3' &&
            //   (!item.accountNo || !accountReg.test(item.accountNo))
            // ) {
            //   return '请输入正确的账号'
            // } else
            if (
              item.accountType !== 3 &&
              item.accountType !== '3' &&
              item.accountNo &&
              item.accountNo.length > 100
            ) {
              return '银行账号应小于100位'
            } else if (!item.accountType) {
              return '请选择账户类型'
            }
            // if (!item.account || !reg.test(item.account)) return '请输入正确的户名'
            // if (!item.bankName || !reg.test(item.bankName)) return '请输入正确的开户行'
            // if (!item.accountNo || !accountReg.test(item.accountNo)) return '请输入正确的账号'
            // console.log(item.accountNo.length > 30)
            // if (item.accountNo && item.accountNo.length > 30) return '银行账号应小于30位'
            // if (!item.account) return '请选择账户类型'
          }
        }
      }
    },
    /**
     * 校验人员信息是否填写完整
     */
    validatePersonnerName () {
      if (this.personnelData && this.personnelData.records) {
        const personnelDataList = this.setParams(
          this.personnelData,
          this.personnelData.records
        )
        const reg = /^[\u0391-\uFFE5A-Za-z]+$/
        // const idCard = /(^\d{18}$)|(^\d{17}(\d|X|x)$)/
        for (const val of personnelDataList.records) {
          if (!val.personnerName) {
            return '请输入人员信息'
          } else if (!val.personnerName || !reg.test(val.personnerName)) {
            return '人员信息姓名只能输入中文或者英文'
          } else if (!val.mobilePhoneNo || !validPhone(val.mobilePhoneNo)) {
            return '请输入正确的人员信息手机号码'
          } else if (!val.dutiesName) {
            return '请输入人员信息职位'
          } else if (val.dictId.length === 0) {
            return '请选择人员类别'
          } else if (val.createAccountFlag === undefined) {
            return '请选择是否创建用户'
          }
          // else if (!val.idCard) {
          //   return '请输入正确的人员信息身份证号码'
          // }
          // else if (!val.address) {
          //   return '请填写家庭地址'
          // }
        }
      }
    },

    /**
     * 表单校验
     */
    formValidate () {
      this.$refs.fromBasicsInfo.validate(validata => {
        if (validata) {
          this.submit()
        } else {
          this.$message.error('请将基本信息填入完整')
        }
      })
    },
    // 提示补全
    tipValidate () {
      this.$refs.fromBasicsInfo.validate(() => {})
    },
    /**
     * 数据提交
     */
    submit () {
      // 账户信息
      if (this.validateAcccount()) {
        this.$message.warning(this.validateAcccount())
        return
      }
      // 人员信息
      if (this.validatePersonnerName()) {
        return this.$message.warning(this.validatePersonnerName())
      }
      let pass = false
      // let legalPersonPass = false
      if (this.personnelData && this.personnelData.records) {
        this.personnelData.records.forEach(item => {
          console.log(item.dictId.indexOf('LEGAL_PERSON'))
          if (item.dictId && item.dictId.indexOf('LEGAL_PERSON') >= 0) {
            // legalPersonPass = true
          }
          if (item.personContactInfos && item.personContactInfos.length > 0) {
            item.personContactInfos.forEach(ele => {
              if (
                !ele.personnalName ||
                // !ele.address ||
                // !ele.idCard ||
                !ele.mobilePhoneNo ||
                !ele.contactType
              ) {
                pass = true
              }
            })
          }
        })
      }

      if (pass) {
        this.warning('请完善人员信息')
        return false
      }
      // if (!legalPersonPass) {
      //   this.warning('当前供应商无法人信息，请确认并添加')
      //   return false
      // }
      // 判断家庭关系信息不能重复且配偶只能存在一位
      let personContactPass = true
      try {
        this.personnelData.records.forEach(item => {
          if (item.personContactInfos && item.personContactInfos.length > 0) {
            const newArr = item.personContactInfos.map(elem => {
              if (elem.contactType === '0') {
                return elem
              }
            })
            if (newArr && newArr.length > 1) {
              throw new Error('配偶重复')
            }
          }
        })
      } catch (err) {
        personContactPass = false
      }
      if (!personContactPass) {
        this.warning('配偶关系只能添加一位')
        return false
      }
      let idCardArrPass = true
      let mobilePhonePass = true
      const idCardArr = []
      const spouseArr = []
      const mobilePhoneArr = []
      const mobileSpousePhoneArr = []
      this.personnelData.records.forEach(item => {
        if (item.idCard && idCardArr.indexOf(item.idCard) !== -1) {
          idCardArrPass = false
        } else {
          item.idCard && idCardArr.push(item.idCard)
        }
        if (mobilePhoneArr.indexOf(item.mobilePhoneNo) < 0) {
          mobilePhoneArr.push(item.mobilePhoneNo)
        } else {
          mobilePhonePass = false
        }
        if (item.personContactInfos && item.personContactInfos.length > 0) {
          item.personContactInfos.forEach(elem => {
            if (elem.idCard && spouseArr.indexOf(elem.idCard) !== -1) {
              idCardArrPass = false
            } else {
              elem.idCard && spouseArr.push(elem.idCard)
            }
            if (mobileSpousePhoneArr.indexOf(elem.mobilePhoneNo) < 0) {
              mobileSpousePhoneArr.push(elem.mobilePhoneNo)
            } else {
              mobilePhonePass = false
            }
          })
        }
      })
      console.log(idCardArr, mobilePhoneArr)
      if (!idCardArrPass) {
        this.warning('身份证信息重复')
        return
      }
      if (!mobilePhonePass) {
        this.warning('电话号码信息重复')
        return
      }
      if (this.litigationListData.length > 0) {
        this.litigationListData.forEach(item => {
          item.companyId = this.enterpriseId
        })
      }

      // 校验身份证信息 弹窗
      const personnelDataList = this.setParams(
        this.personnelData,
        this.personnelData.records
      )
      let idCardPass = true
      // const errArr = []
      personnelDataList.records.forEach((item, index) => {
        if (item.idCard && !idCardReg.test(item.idCard)) {
          // errArr.push(index + 1)
          idCardPass = false
        }
        if (item.personContactInfos && item.personContactInfos.length > 0) {
          item.personContactInfos.forEach((ele, i) => {
            if (ele.idCard && !idCardReg.test(ele.idCard)) {
              // errArr.push(index + 1)
              idCardPass = false
            }
          })
        }
      })
      if (!idCardPass) {
        this.$stateConfirm({
          title: '提示',
          message: '人员身份证信息与18位校验格式不匹配，是否提交？',
          show: true,
          type: 'warning'
        })
          .then(() => {
            this.subInterface()
          })
          .catch(() => {})
      } else {
        this.subInterface()
      }
    },
    subInterface () {
      // 分割传入的城市
      this.baseQueryParas.manageCity = this.baseQueryParas.locationCity[1]
      this.baseQueryParas.manageProvince = this.baseQueryParas.locationCity[0]
      this.baseQueryParas.registerProvince = this.baseQueryParas.locationCity[0]
      this.baseQueryParas.registerCity = this.baseQueryParas.locationCity[1]
      // // 传入完整对象
      const personnelList = this.setParams(
        this.personnelData,
        this.personnelData.records
      )
      // 人员类别 按逗号分割数组
      const newPersonnelList = JSON.parse(JSON.stringify(personnelList))
      if (newPersonnelList.records) {
        newPersonnelList.records.forEach(item => {
          console.log(item.dictId)
          if (item.dictId && item.dictId.length > 0) {
            item.dictId = item.dictId.join(',')
          }
        })
      }
      let newFinancialData = []
      if (this.financialData.length > 0) {
        newFinancialData = JSON.parse(JSON.stringify(this.financialData))
        newFinancialData.forEach(item => {
          item.companyId = this.enterpriseId
        })
      }
      this.baseQueryParas.enterpriseLabel = 1
      this.supplierApi
        .saveall({
          litigationList: this.litigationListData,
          finList: newFinancialData,
          companyInfo: this.baseQueryParas,
          delPersonnelList: this.delectpersonnelList,
          delBankAccountList: this.delectbankAccountList,
          bankAccountList: this.setParams(
            this.accountData,
            this.accountData.records
          ),
          personnelList: newPersonnelList,
          fileList: this.fileData
        })
        .then(res => {
          this.$message.success('提交成功')
          this.$router.back()
        })
        .catch(error => {
          console.log(error)
        })
    },
    /**
     * 增加enterpriseId参数
     * @param type
     * @param data
     * @returns {*}
     */
    setParams (type, data) {
      if (data) {
        data.map(item => {
          item.enterpriseId = this.$route.query.keyId
          return item
        })
        type.records = data
        return type
      }
    },

    /**
     * 人员信息and账户信息删除功能
     * @param type
     * @param index
     * @param row
     */
    removeRow (type, index, row) {
      if (row.sealDefault === 1) {
        this.$message.error('默认签章人,禁止删除')
        return
      }
      if (this.$route.query.type === 'details') {
        this.$message.error('禁止删除')
        return
      }

      // if (parseInt(row.accountType) === 3 || parseInt(row.easUser) === 1) {
      //   this.$message.error('EAS同步数据禁止删除')
      //   return
      // }

      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const keyId = type === 'accountData' ? row.keyId : row.contractkeyId
          this.sendRemove(type, index, keyId)
        })
        .catch(() => {})
    },

    /**
     * 发送删除
     * @param type
     * @param index
     * @param keyId
     */
    sendRemove (type, index, keyId) {
      this.delList(type, index, keyId)
        .then(res => {
          this.$message.success(res)
        })
        .catch(error => {
          this.$message.error(error)
        })
    },

    /**
     * 删除函数
     * @param type
     * @param index
     * @param keyId
     * @returns {Promise<unknown>}
     */
    delList (type, index, keyId) {
      return new Promise((resolve, reject) => {
        if (type === 'accountData') {
          keyId && this.delectbankAccountList.push(keyId)
          this[type].records.splice(index, 1)
          resolve('删除成功')
        }
        if (type === 'personnelData') {
          keyId && this.delectpersonnelList.push(keyId)
          this[type].records.splice(index, 1)
          resolve('删除成功')
        }
      })
    },

    /**
     * 账户信息and人员信息新增
     * @param type
     */
    addTableItem (type) {
      if (type === 'accoun') {
        if (!this.accountData.records) {
          this.$set(this.accountData, 'records', [])
        }
        this.accountData.records.unshift({ addtype: true })
      }
      if (type === 'personnel') {
        if (!this.personnelData.records) {
          this.$set(this.personnelData, 'records', [])
        }
        this.personnelData.records.unshift({
          addtype: true,
          idCard: '',
          createAccountFlag: 0,
          personContactInfos: []
        })
      }
      this.calculateErrorPos(type)
    },

    /**
     * 人员信息，账户信息回显修改
     */
    editTable () {
      if (this.accountData.records) {
        this.accountData.records.map(item => {
          item.accountType = item.accountType.toString()
          if (this.$route.query.type !== 'details') item.addtype = true
          return item
        })
      }
      if (this.personnelData.records) {
        this.personnelData.records.map(item => {
          if (this.$route.query.type !== 'details') item.addtype = true
          return item
        })
      }
    },

    /**
     * 计算表格内容的高度和表格高度的大小
     * @param type
     */
    calculateErrorPos (type) {
      const container =
        type === 'accoun'
          ? this.$refs.accounttableData.$el
          : this.$refs.personnetableData.$el
      const containerBody = findChildByClassName(
        container,
        'el-table__body-wrapper'
      )

      if (containerBody && containerBody.children) {
        containerBody.scrollTop = 0
      }
    },

    /**
     *  动态滚动到相关编辑栏
     * @param id:菜单id
     */
    touchActive (id) {
      this.active = id
      const jump = document.querySelectorAll('.el-collapse-item')
      // 获取需要滚动的距离
      const total = jump[id].offsetTop - 40
      // Chrome
      document.getElementById('content').scrollTop = total
      // this.activeNames = []
    },
    /**
     * 设置滚动位置
     */
    setScroll () {
      const scrollHeiht = document.getElementById('content').scrollTop // 滚动条高度
      const jump = document.querySelectorAll('.el-collapse-item')
      if (scrollHeiht === 0) {
        this.active = 0
      }

      for (let i = 0; i < jump.length; i++) {
        jump[i].index = i
        if (
          scrollHeiht >= jump[i].offsetTop - 10 &&
          scrollHeiht <= jump[i].offsetTop + 260
        ) {
          for (let j = 0; j < jump.length; j++) {
            this.active = i + 1
          }
        }
      }
    },
    // 查看亲密关系
    openPersonnel (row, expandedRows) {
      console.log(row)
    },
    /**
     * 附件信息查看文件列表
     * @param row
     * @param expandedRows
     */
    // 获取当前文件下的列表
    async showCurrentFile (data) {
      this.colIndex = this.fileFromtypeList.findIndex(
        item =>
          data.configItemCode === item.configItemCode &&
          data.configSetCode === item.configSetCode
      )
      const params = {
        configItemCode: data.configItemCode,
        configSetCode: data.configSetCode,
        enterpriseId: this.$route.query.keyId ? this.$route.query.keyId : ''
      }
      const res = await this.supplierFileApi.getsupRecordList(params)
      this.fileFromtypeList[this.colIndex].chide = res.data
    },
    openResumeRow (row, expandedRows) {
      if (expandedRows.length > 0) {
        this.showCurrentFile(row)
      }
      this.tabRow = row
    },

    /**
     * 附件信息删除文件履历
     * @param index
     * @param data
     */
    removeResume (index, data) {
      this.supplierFileApi
        .delsupRecordList({ keyId: data.keyId })
        .then(res => {
          if (res) {
            this.tabRow.chide.splice(index, 1)
            // this.getFileTemplate()
            this.$message.success('文件删除成功')
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    // 查看文件履历
    async openFilesResume (index, data) {
      const params = {
        enterpriseId: this.$route.query.keyId,
        configItemCode: data.configItemCode
      }
      try {
        if (params.enterpriseId) {
          const res = await supplierApi.getFileList(params)
          this.filesResumeData = res.data
        }
      } catch (err) {
        console.log(err)
      }
      this.fileTypeName = data.displayName
      this.visibleFilsResume = true
    },

    /**
     * 附件信息获取文件模板
     */
    getFileTemplate () {
      this.supplierApi
        .getsupFileList({ configSetCode: 'F_GYS_BASEFILE' })
        .then(res => {
          if (res.data) {
            res.data.map(item => {
              item.chide = []
            })
            this.fileFromtypeList = res.data
            this.getAllfile(res.data)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    /**
     * 附件信息文件模板筛选
     * @param data
     * @returns {string|*}
     */
    screenTemplate (data) {
      const template = this.fileData.find(item => {
        if (item) {
          return (
            item.configItemCode === data.configItemCode &&
            item.configSetCode === data.configSetCode
          )
        }
      })
      if (!template) {
        return ''
      } else {
        return template.keyId
      }
    },
    /**
     * 附件信息获取所有文件
     * @param data
     */
    getAllfile (data) {
      data.forEach(item => {
        const params = {
          configItemCode: item.configItemCode,
          configSetCode: item.configSetCode,
          enterpriseId: this.$route.query.keyId ? this.$route.query.keyId : ''
        }
        this.childrenFileList = []
        this.supplierFileApi
          .getsupRecordList(params)
          .then(res => {
            if (res) {
              this.childrenFileList = [...this.childrenFileList, ...res.data]
            }
          })
          .catch(error => {
            console.log(error)
          })
      })
    },
    /**
     * 附件信息文件上传
     * @param data
     * @constructor
     */
    UploadFile (data) {
      if (data.file.fileName.length > 80) {
        return this.warning('文件名称小于80字符')
      }
      const EmojiReg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g
      if (EmojiReg.test(data.file.fileName)) {
        this.warning('文件名不能存在Emoji')
        return false
      }
      const maxSize = 1024 * 10
      const fileSize = Math.round(data.file.fileByte / 1024)
      if (fileSize - maxSize >= 0) {
        return this.warning('文件需小于10M')
      }

      if (!this.baseQueryParas.keyId) {
        this.error('请先检测再上传文件')
        return
      }
      if (!this.$route.query.keyId) {
        this.$route.query.keyId = this.baseQueryParas.keyId
      }
      const obj = {
        configItemCode: data.row.row.configItemCode,
        configSetCode: data.row.row.configSetCode,
        enterpriseId: this.$route.query.keyId,
        fileByte: data.file.fileByte,
        fileId: data.file.keyId,
        fileName: data.file.fileName,
        fileUrl: '',
        oldFileKeyId: this.screenTemplate(data.row)
      }
      this.supplierFileApi.saveFileold(obj).then(res => {
        this.success('文件上传成功')
        this.$set(this.fileData, data.row.$index, res.data)
        // this.getFileTemplate()
        const colIndex = this.fileFromtypeList.findIndex(
          item =>
            data.row.row.configItemCode === item.configItemCode &&
            data.row.row.configSetCode === item.configSetCode
        )
        this.fileFromtypeList[colIndex].chide.unshift(res.data)
      })
      // .catch(error => {
      //   console.log(error)
      //   this.error('文件上传失败')
      // })
    },

    /**
     * 附件信息文件预览
     * @param index
     * @param row
     * @param tab
     * @returns {ElMessageComponent}
     */
    fileview (index, row, tab) {
      // yy
      if (!this.tabRow.chide || this.tabRow.chide.length <= 0) {
        this.$message.error('没有文件，不能预览')
        return
      }
      if (row && tab === 'tab2') {
        this.colIndex = index
        if (!this.fileData[index]) {
          return this.$message.error('没有文件，不能预览')
        }
        const att = this.fileData[index]
        if (att) {
          const FileExt = att.fileName.replace(/.+\./, '')
          this.fileId = att.fileId
          this.fileType = FileExt
        }
      }

      if (row && tab === 'tab1') {
        const FileExt = row.fileName.replace(/.+\./, '')
        this.fileId = row.fileId
        this.fileType = FileExt
      }
      // this.$refs.preView.open()
      // this.visibleView = true
      this.$nextTick(() => {
        this.Count++
      })
    },

    /**
     * 人员信息页码
     * @param data
     */
    personnelPageSizeChange (data) {
      this.peranLqueryParas.pageSize = data
      this.getPersonnelPage()
    },
    /** 人员细腻些
     * 跳转到指定页码
     * @param data
     */
    personnelPageIndexChange (data) {
      this.peranLqueryParas.pageIndex = data
      this.getPersonnelPage()
    },
    /**
     * 人员信息数据获取
     */
    getPersonnelPage () {
      this.supplierApi
        .Personnelpage(this.peranLqueryParas)
        .then(res => {
          if (res) {
            this.personnelData = Object.assign({}, res.data)
            this.personnelData.records.forEach((item, index) => {
              if (!item.personContactInfos) {
                this.$set(
                  this.personnelData.records[index],
                  'personContactInfos',
                  []
                )
              }
            })
            this.$nextTick(() => {
              this.$refs.personnetableData.total =
                this.personnelData.total || 0
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    /**
     * 账户信息分页
     * @param data
     */
    accountpageSizeChange (data) {
      this.accotQueryParas.pageSize = data
      this.getAccountPage()
    },
    /** 账户信息
     * 跳转到指定页码
     * @param data
     */
    accountPageIndexChange (data) {
      this.accotQueryParas.pageIndex = data
      this.getAccountPage()
    },
    /**
     *  账户信息数据获取
     */
    getAccountPage () {
      this.supplierApi
        .Accountpage(this.accotQueryParas)
        .then(res => {
          if (res) {
            this.accountData = Object.assign({}, res.data)
            this.$nextTick(() => {
              this.$refs.accounttableData.total = this.accountData.total || 0
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },

    /**
     * 获取人员类型
     */
    getPersonnel () {
      // 选项数据
      getDictLists('PERSONNEL_TYPE').forEach(item => {
        this.categorYoptions.push({
          dictName: item.dictName,
          dictId: item.dictId
        })
      })
    },

    /**
     * 人员类型筛选
     * @param type
     * @returns {string}
     */
    getPersonnelType (type) {
      const newValue = []
      const data = getDictNameMaps('PERSONNEL_TYPE')
      if (data) {
        type.forEach(item => {
          for (const [key, value] of Object.entries(data)) {
            if (item === value) {
              newValue.push(key)
            }
          }
        })
        return newValue.join(',')
      }
    },

    /**
     * 批量下载
     */
    downAll () {
      const filtKeyIds = this.easFileList.map(item => {
        return item.fileId
      })
      const newFiltKeyIds = [...new Set(filtKeyIds)]
      if (newFiltKeyIds.length > 0 && newFiltKeyIds[0]) {
        downAPi
          .batchDownload({
            keyIds: newFiltKeyIds,
            fileName: '全部附件'
          })
          .then(res => {
            downFile(res)
          })
      } else {
        this.error('抱歉暂无资源')
      }
    },
    /**
     * 单个文件下载
     * @param data
     */
    downloadEnclosure (data) {
      if (!data.fileId) {
        this.error('抱歉暂无资源')
        return
      }
      const params = {
        keyId: data.fileId
      }
      downAPi
        .download(params)
        .then(res => {
          downFile(res)
        })
        .catch(e => {
          this.error(e)
        })
    },
    /**
     * 是否禁用文字提示
     * @param name
     * @param index
     * @returns {boolean}
     */
    getwidth (name, index) {
      if (name) {
        const arr = name.split('')
        if (arr.length > 16) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
    /**
     * 文件图标类型
     * @param suffix
     * @returns {string}
     */
    addFontIcon (suffix) {
      if (suffix) {
        const img = ['jpg', 'png', 'jpeg', 'bmp']
        const compress = ['zip', 'ara']
        const imgFlag = img.some(val => {
          return val === suffix
        })
        const compressFlag = compress.some(val => {
          return val === suffix
        })
        const pdfFlag = suffix === 'pdf'
        if (imgFlag) {
          return 'icontupian'
        } else if (compressFlag) {
          return 'iconyasuobao'
        } else if (pdfFlag) {
          return 'iconpdf'
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.addRelation {
  display: flex;
  align-items: center;
  > div:nth-child(1) {
    flex: 1;
    text-align: center;
  }
  > div:nth-child(2) {
    cursor: pointer;
    background: #2862e7;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    color: #fff;
  }
}
.fliebox {
  background: #f9f9f6;
  width: 100%;
  height: 100%;
}

.table {
  background: #fff;
  /deep/ .el-input.is-focus .el-input__inner {
    border: 1px solid #fdefd5;
  }

  /deep/ .el-select .el-input__inner:focus {
    border: 1px solid #fdefd5;
  }

  /deep/ .el-input__inner {
    border: 1px solid #fdefd5;
  }
}

/deep/ .el-table__expanded-cell[class*="cell"] {
  padding: 0px;
}

.dowload {
  padding-bottom: 10px;
  text-align: right;
}

.box {
  //  padding: 12px;
  // padding-bottom: 10px;
  background: #fff;

  .title {
    color: #abdaf4;
    border-bottom: 2px solid #f5f5f5;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
  }

  .rowbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .boxcontent {
    width: 25%;
    padding: 15px 0;

    .red {
      color: #d43339;
    }

    .transparent {
      color: transparent !important;
    }

    .value {
      max-width: 100%;
      padding-left: 5px;
      word-wrap: break-word;
    }
  }

  .filebox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: #f9f9f6;
    padding: 20px;

    .fileitem {
      width: 33.3333%;
      display: flex;
      padding: 15px 0;
      align-items: center;

      .fileName {
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

downAll {
  margin: 0 20px;
}

.whitebox {
  background: #fff;
  overflow-y: auto;
  height: calc(100% - 90px);
}

.submitbtn {
  height: 60px;
  width: 100%;
  line-height: 60px;
  text-align: center;
  box-shadow: 0px 0px 13px 2px rgba(#4a7cf0, 0.2);
  background: #fff;
}

.footer {
  text-align: center;
  margin: 0px 0px 10px;
  padding: 14px 0;
  box-shadow: 0px 0px 13px 2px rgba(#4a7cf0, 0.2);
  background: #ffffff;
}

.leftitem {
  background: #f9f9f6;
  padding: 12px;

  /deep/ .el-cascader {
    width: 100%;
  }

  .addbtn {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    background: #fff;
    padding-bottom: 10px;
    padding-right: 19px;

    .addbutton {
      background: #4a7cf0;
      width: 20px;
      height: 20px;
      color: #fff;
      text-align: center;
      line-height: 20px;
      cursor: pointer;

      &:hover {
        background-color: #6996ff;
      }
    }
  }

  .filebox {
    padding: 20px;
  }

  .fileitem {
    width: 500px;
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #ccc;
  }
}

.point {
  position: fixed;
  top: 20vh;
  right: 24px;
  border-bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;

  .item {
    box-shadow: 0 0 3px #fef3df;
    position: relative;
  }

  .itemactive {
    &::after {
      content: "";
      width: 0;
      height: 0;
      border-top: 4.3px solid transparent;
      border-left: 4.3px solid #fff;
      border-bottom: 4.3px solid transparent;
      position: absolute;
      top: calc(50% - 4.3px);
      left: 0;
    }
  }

  .point-top {
    width: 37px;
    height: 37px;
    line-height: 37px;
    background: #4a7cf0;
    border-radius: 50%;
    text-align: center;
    margin-bottom: 10px;

    .icondaohang {
      font-size: 18px;
      color: #fff;
    }
  }

  .scrollTop {
    width: 110px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    font-size: 30px;
    font-weight: 300;
    color: #4a7cf0;
    background-color: #ebf1ff;

    .icontop {
      font-size: 30px;
    }
  }

  .item {
    width: 110px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
    font-weight: 300;
    color: #757380;
    background-color: #fff;

    &:hover {
      background-color: #f9f9f6;
    }
  }

  .itemactive {
    background: #4a7cf0 !important;
    flex-wrap: bold;
    color: #fff;

    .upload-demo {
      display: flex;
      flex-direction: column;
    }
  }
}

.left {
  padding: 15px;
  background: #fff;
  margin-right: 110px;
  padding-bottom: 70px;

  .leftitem {
    padding: 0;
  }
}

.content {
  position: relative;
  height: 100% !important;
}

.pd15 {
  padding: 15px;
}

.tableFile {
  height: 100%;
}
</style>
