<template>
    <base-button
      :http-request="UploadFile"
      btnType="upload"
      label=""
      accept=".doc,.docx,.pdf,.png,.jpg,.jpeg"
      class="el_upload"
      icon='iconfont iconshangchuan'></base-button>
</template>
<script>
import BaseButton from '@/components/common/button/base-button/base-button.vue'
// import { supportFileTypes, supportImagesFileType } from '@/components/pre-view/pre-view'
import { fileAPi } from '@/api/fileApi'

export default {
  name: 'supplierUpload',
  data () {
    return {
    }
  },
  props: {
    // 上传文件信息
    tabRow: {
      type: Object,
      default: () => {}
    }
  },
  components: { BaseButton },
  methods: {
    /***
     * 上传文件
     * @param param
     * @returns {*}
     * @constructor
     */
    UploadFile (param) {
      const filetype = ['doc', 'docx', 'pdf', 'jpg', 'png', 'jpeg'] // [...supportImagesFileType, ...supportFileTypes]
      const FileExt = (param.file.name.replace(/.+\./, '')).toLowerCase()

      if (!filetype.includes(FileExt)) {
        return this.$message.info('请上传doc,docx,pdf,png,jpg,jpeg格式文件')
      }

      if (param.file.name.length > 100) {
        return this.$message.error('文件名过长,请修改')
      }
      // 创建一个fromdData
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('belongCode', 'TEMPLATE')
      fileAPi.file(formData).then(res => {
        if (res.success) {
          this.$emit('uploadFile', { file: res.data, row: this.tabRow })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.el_upload {
  margin-left: 10px;
  width: 32px;
  height: 32px;
  border-radius:50% ;
  background-color: transparent;
  /deep/ .el-upload{
    width: 100%;
    button{
      display: block;
      background-color: transparent;
      color: #2862E7;
      border: none;
      box-shadow: 0 0 0 0;
      margin: 0;
      padding: 0;
      width: 32px;
      height: 32px;
      min-width: 32px;
      border-radius:50% ;
      text-align: center;
      .iconfont{
        margin:0 ;
      }
      span{
        display: none;
      }
    }
  }
}
</style>
